import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../../components/Core";

import styled from "styled-components";

import titleImage from "../../assets/image/jpeg/usa.jpg";
import iconQuote from "../../assets/image/png/quote-icon.png";
import styles from '../../assets/styles/blog.module.css';

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
  <Badge
    mr={3}
    mb={3}
    bg="#eae9f2"
    color="#696871"
    fontSize="16px"
    px={3}
    py={2}
  >
    {children}
  </Badge>
);

const BlogDetails = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="12">
                <Title variant="hero">
                Now get all your favorite Australian products on your doorstep in the United States via direct mail!
                  <br className="d-none d-lg-block" />
                </Title>
                <Box className="d-flex justify-content-center">
                  <Text mr={3}>Feb 13, 2020
                  </Text>
                  <Text>Michael Tippett
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pb-0 pt-4">
          <Container>
            <Row>
              <Col lg="12" className="mb-5">
                <Post>
                  <div>
                    <img src={titleImage} alt="Shopping From USA" className={styles.titleImage} />
                  </div>
                  <div>
                    <p>The introduction of our parcel forwarding service has made directly mailing products from Australia to the US easier than ever. Using this service saves you a lot of time and worry, you just have to provide the address we give to you to the merchant you are shopping from, giving you the possibility to order from both offline and online stores!</p>
                    <p>This is a splendid mail redirection solution for global merchants and traders as it saves a lot of money on shipments!</p>
                    <p>The moment we receive your package, our professional team starts to make sure that all damage control, consolidation, and warehousing services are met to the highest of standards, making your delivery fast and secure. Gone are the days of damaged products and pricey shipments! We aim to provide the best quality service around.</p>                    
                    <a href="https://members.hotsnail.com.au/signup">Sign up to HotSnail now</a>
                  </div>
                </Post>
                <Box className="d-flex" mt={4}>
                  <BadgePost>USA</BadgePost>
                  <BadgePost>Shopping</BadgePost>
                  <BadgePost>Mail forwarding</BadgePost>

                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default BlogDetails;
